<template>
  <Toast />
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '650px' }"
    :header="`ADICIONAR PRODUCTOS A PAQUETE: ${data_productos.nombre} - ${data_productos.id}`"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <label for=""><strong>NOMBRE PRODUCTO/SERVICIO:</strong></label>
          <div class="p-inputgroup">
            <AutoComplete
              field="descripcion"
              v-model="productoSelected"
              :suggestions="productosListadoFiltrado"
              @complete="buscarProducto($event)"
              placeholder="Buscar Producto"
              @item-select="agregarProductoDetalle"
            >
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.item.descripcion }}</strong
                  ><span
                    ><strong> --- </strong
                    ><!-- </span>
                      {{ slotProps.item.fabricas.nombre_proveedor
                      }}<span><strong> ---</strong> --></span
                  >
                  {{ slotProps.item.fabrica_nombre
                  }}<span><strong> ---</strong></span>
                </div>
              </template>
            </AutoComplete>
            <Button
              icon="pi pi-plus"
              class="p-button-primary"
              v-tooltip.top="'Agregar al Producto al detalle'"
              @click="agregarProductoDetalle"
            >
            </Button>
            <small class="p-invalid" v-if="errors.productoSelected">{{
              errors.productoSelected[0]
            }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 col-12">
      <DataTable
        ref="reporte_stock"
        key="id"
        :value="detalle_productos"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        responsive="true"
        editMode="cell"
        stripedRows
        showGridlines
        @cell-edit-complete="guardandoCambiosCelda"
      >
        <template #loading
          ><div class="flex align-items-center justify-content-center">
            <ProgressSpinner /></div
        ></template>
        <template #empty>
          <div class="flex align-items-center justify-content-center">
            <div class="p-invalid">No existen Productos en el detalle!</div>
          </div>
        </template>
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="producto" header="DETALLE/PRODUCTO"></Column>
        <Column class="text-center" field="cantidad" header="CANTIDAD">
          <template #body="slotProps">
            {{ slotProps.data.cantidad }}
          </template>
          <template #editor="slotProps">
            <InputNumber
              :inputClass="'text-right'"
              :min="0"
              :minFractionDigits="0"
              locale="de-DE"
              v-model="slotProps.data.cantidad"
            />
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              @click="quitarProductoDetalle(slotProps)"
              v-tooltip.top="'Quitar Producto'"
              icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <template #footer>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="ADICIONAR PRODUCTOS"
        icon="pi pi-check"
        class="p-button-info p-button-lg"
        @click="savePaqueteProductos"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import PaqueteProductoService from "@/service/PaqueteProductoService";
import ProductService from "@/service/ProductService";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    producto: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  paqueteProductoService: null,
  productService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_productos: this.producto,
      enviado: false,
      errors: {},
      productoSelected: null,
      productosListadoFiltrado: [],
      detalle_productos: [],
      enviando: false,
      stockClass: (data) => {
        if (data.cantidad <= 0) {
          return "text-red-500";
        } else if (data.cantidad <= 5) {
          return "text-yellow-500";
        } else {
          return "text-green-500";
        }
      },
    };
  },
  created() {
    this.paqueteProductoService = new PaqueteProductoService();
    this.productService = new ProductService();
  },
  methods: {
    savePaqueteProductos() {
      if (this.detalle_productos.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe agregar productos al detalle!",
          life: 3000,
        });
        return false;
      }
      this.enviando = true;
      let datos = {
        // envio el mismo id del paquete
        id: this.data_productos.id,
        productos: this.detalle_productos,
      };

      this.paqueteProductoService.savePaqueteProductos(datos).then((data) => {
        if (data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: data.mensaje,
            life: 3000,
          });
          this.enviando = false;
          this.detalle_productos = [];
          this.$emit("actualizarListado");
          this.$emit("closeModal");
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: data.mensaje,
            life: 3000,
          });
          this.enviando = false;
          this.detalle_productos = [];
        }
      });
    },
    quitarProductoDetalle(slotProps) {
      this.detalle_productos.splice(slotProps.rowIndex, 1);
      this.productoSelected = null;
    },
    guardandoCambiosCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;

      if (field == "cantidad" && newValue <= 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "La cantidad debe ser mayor a 0",
          life: 3000,
        });
        data[field] = 1;
      }
      if (this.cantidad <= 0 || this.cantidad === null) {
        this.errors.cantidad = ["La cantidad debe ser mayor a 0"];
        return false;
      }
    },
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.detalle_productos = [];
      this.$emit("closeModal");
      this.$emit("actualizarListado");
    },
    agregarProductoDetalle() {
      if (this.productoSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un producto",
          life: 3000,
        });
        return false;
      }

      // Recorro el detalle de productos para verificar que no se repita el producto
      let existe = false;
      this.detalle_productos.forEach((element) => {
        if (element.producto_id == this.productoSelected.id) {
          existe = true;
        }
      });

      if (existe) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya se encuentra en el detalle",
          life: 3000,
        });
        return false;
      }

      let producto = this.productoSelected;
      let cantidad = 1;
      let detalle = {
        producto_id: producto.id,
        paquete_producto_id: this.data_productos.id,
        producto: producto.descripcion,
        cantidad: cantidad,
      };
      this.detalle_productos.push(detalle);
      this.productoSelected = null;
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    producto(val) {
      this.data_productos = val;
      this.data_productos.paqueteproductos.forEach((element) => {
        let detalle = {
          producto_id: element.producto_id,
          paquete_producto_id: element.paquete_producto_id,
          producto: element.producto.descripcion,
          cantidad: element.cantidad,
        };
        this.detalle_productos.push(detalle);
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
